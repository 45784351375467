import clickOutside from "../directives/click-ouside.js";
import VueTheMask from 'vue-the-mask';
import VueMeta from 'vue-meta';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive("click-outside", clickOutside);
    Vue.use(VueTheMask);
    Vue.use(VueMeta, {
      refreshOnceOnNavigation: true
    });
  }
};

export default GlobalDirectives;
