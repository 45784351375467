const meta = {
  title: 'Bairon | Tech | Serviços de Tecnologia',
  description: 'Tech - Desenvolvimento de soluções seguras utilizando as melhores tecnologias',
  siteName: 'Tech',
  logoPath: '/logodb.png',
  indexOnGoogle: true
};

export default [
  {
    path: "/consultoria-e-tecnologia",
    name: "tech",
    components: {
      header: () => import('@/components/HeaderNavigation.vue'),
      default: () => import('@/views/Tech/HomePage.vue'),
      footer: () => import('@/components/FooterNavigation.vue'),
    },
    meta: {
      title: meta.title,
      description: meta.description,
      siteName: meta.siteName,
      logoPath: meta.logoPath,
      indexOnGoogle: meta.indexOnGoogle
    }
  }
];