const meta = {
  title: 'Bairon - Registro de Marcas',
  description: 'Bairon - Registramos sua marca',
  siteName: 'Tech',
  logoPath: '/logodb.png',
  indexOnGoogle: true
};

export default [
  {
    path: "/registro-de-marcas",
    name: "brands",
    components: {
      header: () => import('@/components/HeaderNavigation.vue'),
      default: () => import('@/views/Brand/RegisterBrand.vue'),
      footer: () => import('@/components/FooterNavigation.vue')
    },
    meta: {
      title: meta.title,
      description: 'Registre sua marca',
      siteName: meta.siteName,
      logoPath: meta.logoPath,
      indexOnGoogle: meta.indexOnGoogle
    }
  }
];