<template>
  <section class="section section-lg pt-lg-0 section-contact-us" id="formContatUs">
    <div class="container">
      <div class="row justify-content-center mt--30">
        <form class="col-lg-8" @submit.prevent="sendContact" >
          <card gradient="secondary" shadow body-classes="p-lg-5">
            <div v-if="req.success != null" class="row text-center align-items-center justify-content-center">
              <span class="span-message col-12" v-if="req.success">
                Mensagem enviada com sucesso!
              </span>
              <span class="span-message col-12" v-else>
                Mensagem não enviada. {{req.message}}
              </span>
              <base-button @click="req.success = null">
                Enviar nova mensagem
              </base-button>
            </div>
            <div v-if="req.success == null">
              <h4 class="mb-1">Me conte mais sobre seu projeto</h4>
              <p class="mt-0">
                Quero ouvir você. Descreva o que precisa através do formulário abaixo e te retornoarei em até 2 horas.
              </p>
              <base-input
                name="name"
                :error="fieldsError.name"
                @removeError="()=>{ fieldsError.name = null }"
                v-model="contact.name"
                class="mt-5"
                alternative
                placeholder="Seu nome"
                addon-left-icon="ni ni-user-run"></base-input>
              <base-input
                name="phone"
                :error="fieldsError.phone"
                @removeError="()=>{ fieldsError.phone = null }"
                v-model="contact.phone"
                alternative
                addon-left-icon="fa fa-phone"
                placeholder="Telefone"
                :mask="['(##) ####-####', '(##) #####-####']"></base-input>
              <base-input
                name="email"
                :error="fieldsError.email"
                @removeError="()=>{ fieldsError.email = null }"
                v-model="contact.email"
                alternative
                addon-left-icon="ni ni-email-83"
                placeholder="E-mail"></base-input>
              <base-input class="mb-4"
                :error="fieldsError.message"
                name="message">
                <textarea
                  v-model="contact.message"
                  class="form-control form-control-alternative"
                  name="message"
                  rows="4"
                  cols="80"
                  placeholder="Digite a mensagem..."></textarea>
              </base-input>
              <base-button
                :isLoading="req.loading"
                nativeType="submit"
                type="default"
                round block size="lg">
                Enviar mensagem
              </base-button>
              <div class="location-detail row mt-5 justify-content-center">
                <div class="email col-md-5 col-lg-6">
                  <i class="ni ni-email-83 mr-2"></i>
                  <span>
                    <a href="mailto:contato@bairon.com.br">contato@bairon.com.br</a>
                  </span>
                </div>
                <div class="whatsapp col-md-5 col-lg-6 text-lg-right text-sm-left">
                  <img src="/img/icons/whatsapp.png" class="mr-2" @click="redirectWhatsApp" />
                  <span>
                    <a href="tel:5511934450710">(11) 9 3445-0710</a>
                  </span>
                </div>
              </div>
            </div>
          </card>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { isValidEmail } from '@/services/validations';

export default {
  name: "ContactForm",
  data() {
    return {
      contact: {
        name: null,
        email: null,
        phone: null,
        message: null
      },
      req: {
        success: null,
        message: '',
        loading: false
      },
      fieldsError: {
        message: null,
        name: null,
        email: null,
        phone: null
      }
    };
  },
  props: {
    showLocationDetail: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    async sendContact() {
      if(!this.isValidateFields()) {
        return null;
      }
      this.req.loading = true;
      await this.$api.post(`/contact`, {
        email: this.contact.email,
        name: this.contact.name,
        phone: this.contact.phone,
        message: this.contact.message
      })
      .then(({msg}) => {
        this.req.success = true;
        this.contact = {};
      })
      .catch((err) => {
        this.req.success = false;
        this.req.message = err.response.data.message;
      });
      this.req.loading = false;
    },
    redirectWhatsApp() {
      window.open(
        "https://api.whatsapp.com/send?phone=5511934450710&text=Ola, gostaria de saber sobre seus serviços.",
        "_blank"
      );
    },
    isValidateFields() {
      if(!this.contact.name || this.contact.name.length < 5) {
        this.fieldsError.name = 'Insira um nome válido';
      } else if(!isValidEmail(this.contact.email)) {
        this.fieldsError.email = 'Insira um e-mail válido';
      } else if(!this.contact.message || this.contact.message.length < 20) {
        this.fieldsError.message = 'Descreva seu negócio para nós. Queremos saber mais. :)';
      }
      return (!this.fieldsError.email && !this.fieldsError.message && !this.fieldsError.name);
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.section-contact-us {
  .span-message{
    display: block;
    margin: 30px;
    text-align: center;
  }
  .location-detail {
    .email {
      i {
        vertical-align: middle;
      }
    }
    .whatsapp {
      img {
        height: 22px;
        opacity: 0.67;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 767px){
    .location-detail {
      text-align: center!important;
      flex-direction: column-reverse;
      .email {
        margin-top: 20px;
      }
      .whatsapp {
        text-align: center!important;
      }
    }
  }
}
</style>