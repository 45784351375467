import axios from 'axios';
import { getCookie, removeCookie } from './cache';

var api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

api.interceptors.request.use((config) => {
  config.headers.token = `${getCookie('token')}`;
  return config
}, (err) => {
  return Promise.reject(err)
})

api.interceptors.response.use(res => {
  const { data } = res;
  if (!data) return false;
  if (data.code >= 400 && data.code <= 500) {
    //console.log('Error status code: ', data.code);
  }
  return res
}, ( err ) => {
  if (err.response && err.response.status && err.response.status === 401) {
    removeCookie('token');
    window.location.reload(); // Usuário não logado
  }
  if (!err.response) {
    return Promise.reject(new Error('Problema com servidor')); // Quando a API está inacessível
  }
  return Promise.reject(err)
});

export default api;
