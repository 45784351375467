<template>
  <component :is="tag"
              :type="tag === 'button' ? nativeType: ''"
              @click="handleClick"
              class="btn"
              :class="classes">
    <span class="btn-inner--icon" v-if="$slots.icon || icon && $slots.default">
      <slot name="icon">
        <i :class="icon"></i>
      </slot>
    </span>
    <i v-if="!$slots.default" :class="icon"></i>
    <span class="btn-inner--text" v-if="$slots.icon || icon && $slots.default"><slot>{{text}}</slot></span>
    <slot v-if="!$slots.icon && !icon"></slot>
    <span class="loading" v-if="isLoading">
      <img src="/img/loading.svg"/>
    </span>
  </component>
</template>

<script>
  export default {
    name: "base-button",
    props: {
      tag: {
        type: String,
        default: "button",
        description: "Button tag (default -> button)"
      },
      type: {
        type: String,
        default: "default",
        description: "Button type (e,g primary, danger etc)"
      },
      size: {
        type: String,
        default: "",
        description: "Button size lg|sm"
      },
      textColor: {
        type: String,
        default: "",
        description: "Button text color (e.g primary, danger etc)"
      },
      nativeType: {
        type: String,
        default: "button",
        description: "Button native type (e.g submit,button etc)"
      },
      icon: {
        type: String,
        default: "",
        description: "Button icon"
      },
      text: {
        type: String,
        default: "",
        description: "Button text in case not provided via default slot"
      },
      outline: {
        type: Boolean,
        default: false,
        description: "Whether button style is outline"
      },
      rounded: {
        type: Boolean,
        default: false,
        description: "Whether button style is rounded"
      },
      iconOnly: {
        type: Boolean,
        default: false,
        description: "Whether button contains only an icon"
      },
      block: {
        type: Boolean,
        default: false,
        description: "Whether button is of block type"
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      classes() {
        let btnClasses = [{
            "btn-block": this.block
          },
          {
            "rounded-circle": this.rounded
          },
          {
            "btn-icon-only": this.iconOnly
          },
          {
            [`text-${this.textColor}`]: this.textColor
          },
          {
            "btn-icon": this.icon || this.$slots.icon
          },
          this.type && !this.outline ? `btn-${this.type}` : "",
          this.outline ? `btn-outline-${this.type}` : ""
        ];
        if (this.size) {
          btnClasses.push(`btn-${this.size}`);
        }
        return btnClasses;
      }
    },
    methods: {
      handleClick(evt) {
        if (!this.isLoading)
          this.$emit('click', evt);
      }
    }
  };
</script>
<style lang="scss">
  .btn {
    .loading {
      background-color: #00000059;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      img {
        height: 30px;
        margin: 10px auto;
      }
    }
  }
</style>