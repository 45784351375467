<template>
    <div id="app">
        <router-view name="header"></router-view>
        <main>
            <fade-transition origin="center" mode="out-in" :duration="250">
                <router-view/>
            </fade-transition>
        </main>
        <router-view name="footer"></router-view>
    </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    FadeTransition
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      htmlAttrs: {
        lang: 'pt-BR',
        amp: true
      },
      meta: [
        { name: 'description', content:  this.$route.meta.description },
        { property: 'og:title', content: this.$route.meta.title },
        { property: 'og:site_name', content: this.$route.meta.siteName },
        { property: 'og:type', content: 'Site' },
        { property: 'og:image', content: this.$route.meta.logoPath },
        { property: 'og:url', content: window.location.origin },
        {
          name: 'robots',
          content: (this.$route.meta.indexOnGoogle ? 'index,follow': 'noindex,nofollow')
        }
      ]
    }
  }
};
</script>
