const meta = {
  title: 'Bairon',
  description: 'Bairon - O especialista em meio de pagamento.',
  siteName: 'Bairon',
  logoPath: '/image/logo_bairon.png',
  indexOnGoogle: true
};

export default [
  {
    path: "/solucoes-bancarias-e-meio-de-pagamento",
    name: "Banking",
    components: {
      header: () => import('@/components/HeaderNavigation.vue'),
      default: () => import('@/views/Banking/HomePage.vue'),
      footer: () => import('@/components/FooterNavigation.vue')
    },
    meta: {
      title: meta.title,
      description: meta.description,
      siteName: meta.siteName,
      logoPath: meta.logoPath,
      indexOnGoogle: meta.indexOnGoogle
    }
  }
];