
  export function isValidDocument(document) {
    if (!document || document.length === 0)
      return false;

      const doc = document.replace(/[^0-9]/g, "");
      return (isValidCPF(doc) || isValidCNPJ(doc));
  }

  export function formatDataFromServer(date) {
    let newDate = date.split('-');;
    return newDate[2] + '/' + newDate[1] + '/' + newDate[0];
  }

  export function isValidCNPJ(cnpj) {

    cnpj = cnpj.replace(/[^\d]+/g,'');

    if(cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return false;

    return true;
  }

  export function isValidCPF(doc) {
    if (!doc || doc.toString().length !== 11 || /^(\d)\1{10}$/.test(doc.replace(/\D/g, '')))
      return false;

    const cpf = doc.replace(/\D/g, '');

    let result = true;
    [9, 10].forEach((j) => {
      let sum = 0;
      let r;
      cpf.split(/(?=)/).splice(0, j).forEach((e, i) => {
        sum += parseInt(e, 10) * ((j + 2) - (i + 1));
      });
      r = sum % 11;
      r = (r < 2) ? 0 : 11 - r;
      if (r !== parseInt(cpf.substring(j, j + 1), 10)) {
        result = false;
      }
    });
    return result;
  }

  export function isValidEmail(email) {
    const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(email);
  }

  export function cleanDocument(doc) {
    return doc.replace(/[^0-9]/g, "");
  }

  export const moneyMask = (n, hidePrefix) => { // hidePrefix true 'e usado para mostrar o R$
    if (n === null) return null;
    n = parseFloat(n);
    let options = {};
    if (!hidePrefix) options = { style: 'currency', currency: 'BRL' };
    if (hidePrefix) options = { minimumFractionDigits: 2 };
    return n.toLocaleString('pt-BR', options);
  }