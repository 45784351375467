<template>
  <section class="section section-sm scoped-application">
    <div class="container col-10 mx-auto">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-2">
          <img :src="imageName ? ('/image/'+imageName) : imagePath" class="img-fluid floating">
        </div>
        <div class="col-md-6 order-md-1">
          <div class="pr-md-5">
            <icon :name="`fa fa-${icon}`" class="mb-5" :style="iconStyle" size="lg" :type="color || 'success'" shadow
                rounded></icon>
            <h3 v-html="title" />
            <p v-html="text" />
            <ul class="list-unstyled mt-5">
              <li
                class="py-2"
                v-for="(item, index) in topics"
                :key="index">
                <div class="d-flex align-items-center">
                  <badge :type="item.type || 'success'" circle class="mr-3" :icon="`fa fa-${item.icon}`" />
                  <h6 v-html="item.text" class="mb-0"/>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BaseProduct',
  props: {
    imageName: {
      type: String,
      default: null
    },
    imagePath: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    iconStyle: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    topics: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.scoped-application {

}
</style>
