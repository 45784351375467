export function createCookie(name, value, minutes) {
  var expires;
  var date = new Date();
  date.setTime(date.getTime() + (minutes * 60 * 1000));
  expires = "; expires=" + date.toGMTString();
  document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

export function removeCookie(name) {
  var date = new Date()
  date.setTime(date - (24 * 60 * 60 * 1000)) // 24 * 60 * 60 * 1000
  window.document.cookie = name + '=null; expires=' + date.toGMTString() + '; path=/'
}

export function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return decodeURIComponent(parts.pop().split(";").shift());
}

export function hasCookie(name) {
  var cookie = this.getCookie(name);
  return !(!cookie);
}

export function setCache(name, value) {
  localStorage.setItem(name, value);
}

export function getCache(name) {
  return localStorage.getItem(name);
}

export function hasCache(name) {
  return (localStorage.getItem(name) !== null);
}

export function removeCache(name) {
  localStorage.removeItem(name);
}

